import React from "react";
import Footer from "../components/Footer";
import { AboutPageStyle, ContentStyle } from "../styles";
import CONFIG from "../CONFIG";

const Terms = () => (
  <div style={AboutPageStyle}>
    <h1>{CONFIG.TERMS_PAGE.HEADER}</h1>
    <span>
      <b>Last Updated:</b> {CONFIG.TERMS_PAGE.UPDATED_TIME}
    </span>
    <div style={ContentStyle}>
      <ul>
        {CONFIG.TERMS_PAGE.TERMS.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
    <Footer style={{ alignSelf: "stretch" }} />
  </div>
);

export default Terms;
