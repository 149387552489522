import React from "react";
import Button from "../Button";
import { Link } from "react-router-dom";
import { colors, isMobile } from "../../styles";
import CONFIG from "../../CONFIG";
const { textInvert } = colors;

const MenuStyle = {
  listStyleType: "none",
  display: "flex",
  alignItems: "center",
  fontSize: isMobile() ? "1rem" : "1.3rem",
  margin: isMobile() ? 8 : "10px 20px",
};

const LinkStyle = {
  textDecoration: "none",
  margin: "0px 10px",
  color: textInvert,
};

const Menu = ({ share }) => {
  const links = [
    {
      title: CONFIG.GLOBAL.PRIMARY_BUTTON_TEXT,
      onClick: share,
      primary: true,
    },
  ];
  return (
    <ul style={MenuStyle}>
      {links.map(({ primary, title, onClick, route }) => {
        return primary ? (
          onClick ? (
            <Button key={title} onClick={() => onClick()}>
              {title}
            </Button>
          ) : (
            <Link key={title} style={LinkStyle} to={route}>
              <Button>{title}</Button>
            </Link>
          )
        ) : onClick ? (
          <li key={title} onClick={() => onClick()}>
            {title}
          </li>
        ) : (
          <Link key={title} style={LinkStyle} to={route}>
            {title}
          </Link>
        );
      })}
    </ul>
  );
};

export default Menu;
