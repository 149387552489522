import React, { useEffect } from "react";
import Button from "../Button";
import { colors } from "../../styles";
import { postMetadata } from "../../utils";
import bolt from "../../assets/bolt.png";
import CONFIG from "../../CONFIG";

const { primary, textInvertColor, accent } = colors;
const FileMetadataPage = {
  backgroundImage: `linear-gradient(to right, ${primary} , ${accent})`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  color: textInvertColor,
};

const ProtectionStyle = {
  listStyleType: "none",
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  color: textInvertColor,
  fontSize: "1.3rem",
};

const IconStyle = {
  width: 30,
  height: 30,
  margin: 10,
};

const FileImage = {
  height: "15%",
  borderRadius: "50%",
};

const FileMetadata = ({ checked, meta, setLoading }) => {
  useEffect(() => {
    const initialize = async () =>
      await postMetadata({ msg: `File protections page` });
    initialize();
  }, []);

  const acknowledge = async () => {
    try {
      setLoading(true);
      await postMetadata({ msg: `File protections acknowledged` });
      checked(true);
    } catch (err) {
      await postMetadata({
        msg: `File protections acknowledgement error`,
        body: err,
      });
    } finally {
      setLoading(false);
    }
  };

  const protections = CONFIG.ENABLED_PROTECTIONS.PROTECTIONS(meta);
  return (
    <div style={FileMetadataPage}>
      <img src={bolt} style={FileImage} alt="The Quick File" />
      <h2>{CONFIG.ENABLED_PROTECTIONS.HEADER}</h2>
      <ul>
        {protections.map(({ icon, title }) => (
          <li key={title} style={ProtectionStyle}>
            <img src={icon} alt={`${title} icon`} style={IconStyle} />
            {title}
          </li>
        ))}
      </ul>
      <Button autoFocus onClick={acknowledge}>
        {CONFIG.ENABLED_PROTECTIONS.BUTTON}
      </Button>
    </div>
  );
};

export default FileMetadata;
