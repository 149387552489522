import React from "react";
import Button from "../Button";
import { colors } from "../../styles";
import wave from "../../assets/wave.png";
import CONFIG from "../../CONFIG";

const TEXT = CONFIG.MAIN_PAGE.HERO;
const BUTTON_TEXT = CONFIG.GLOBAL.PRIMARY_BUTTON_TEXT;
const { textInvertColor, primary, accent } = colors;
const HeroBox = {
  color: textInvertColor,
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "94vh",
  marginTop: "6vh",
  backgroundImage: `linear-gradient(to right, ${primary} , ${accent})`,
};

const FileImage = {
  height: "20%",
};

const PrimaryButton = {
  margin: "15px 0",
  fontSize: "1.5rem",
};

const TagLine = {
  maxWidth: 800,
  minWidth: 300,
  width: "70%",
};

const Hero = ({ share }) => {
  return (
    <div style={HeroBox}>
      <img src={wave} style={FileImage} alt="The Quick File" />
      <h1 style={TagLine}>{TEXT.TAG_LINE}</h1>
      <div style={TagLine}>{TEXT.SUB_TAG}</div>
      <Button onClick={share} style={PrimaryButton}>
        {BUTTON_TEXT}
      </Button>
    </div>
  );
};

export default Hero;
