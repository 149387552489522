import React from "react";
import { colors } from "../../styles";

const { highlight, textColor, accent } = colors;

const ButtonStyle = {
  // border: `3px solid ${accent}`,
  padding: 7,
  borderRadius: 4,
  backgroundColor: highlight,
  color: textColor,
  cursor: "pointer",
  boxShadow: `5px 5px ${accent}`,
};

const Button = ({ children, style, ...rest }) => (
  <div style={{ ...ButtonStyle, ...style }} {...rest}>
    {children}
  </div>
);

export default Button;
