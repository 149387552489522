import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { postMetadata, getFileMetadata } from "../utils";
import PasswordProtect from "../components/PasswordProtect";
import GeoProtect from "../components/GeoProtect";
import FileRetriever from "../components/FileRetriever";
import FileNotFound from "../components/FileNotFound";
import LinkExpired from "../components/LinkExpired";
import FileMetadata from "../components/FileMetadata";
import Footer from "../components/Footer";
import LoaderPage from "../components/LoaderPage";

const Retrieve = () => {
  const { id } = useParams();
  const [meta, setMeta] = useState({});
  const [metaChecked, setMetaChecked] = useState(false);
  const [fileNotFound, setFileNotFound] = useState(false);
  const [loading, setLoading] = useState(true);

  const [password, setPassword] = useState(null);
  const [geolocation, setGeolocation] = useState(null);

  const linkExpired = meta.expires && new Date(meta.expires) <= new Date();

  const loadedAndFound = !loading && !fileNotFound;

  const showMetaPage = loadedAndFound && !metaChecked;

  const showPassPage =
    !linkExpired &&
    loadedAndFound &&
    !showMetaPage &&
    meta.password &&
    !password;

  const showGeoPage =
    !linkExpired &&
    loadedAndFound &&
    !showPassPage &&
    !showMetaPage &&
    meta.geolocation &&
    !geolocation;

  const retrieveFile =
    !linkExpired &&
    loadedAndFound &&
    !showPassPage &&
    !showGeoPage &&
    !showMetaPage;

  useEffect(() => {
    const initialize = async () =>
      await postMetadata({ msg: `File retrieval initialized` });
    initialize();
  }, []);

  useEffect(() => {
    const getMetaData = async () => {
      try {
        const { data } = await getFileMetadata(id);
        setMeta(data);
      } catch (err) {
        setFileNotFound(true);
      } finally {
        setLoading(false);
      }
    };
    getMetaData();
  }, [id]);

  return (
    <Fragment>
      {loading && <LoaderPage />}
      {fileNotFound && <FileNotFound />}
      {linkExpired && <LinkExpired />}
      {showMetaPage && (
        <FileMetadata
          meta={meta}
          checked={setMetaChecked}
          setLoading={setLoading}
        />
      )}
      {showPassPage && <PasswordProtect id={id} setPassword={setPassword} />}
      {showGeoPage && <GeoProtect setGeolocation={setGeolocation} />}
      {retrieveFile && (
        <FileRetriever
          id={id}
          onetime={meta.onetime}
          passwordRequired={meta.password}
          password={password}
          geolocation={geolocation}
          zip={meta.zip}
        />
      )}
      <Footer />
    </Fragment>
  );
};

export default Retrieve;
