import React from "react";
import { colors, isMobile } from "../../styles";
import CONFIG from "../../CONFIG";

const { highlight, textInvertColor, primary, accent } = colors;

const BodyStyle = {
  color: textInvertColor,
  padding: 10,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: `linear-gradient(to right, ${primary} , ${accent})`,
};

const FeatureListStyle = {
  listStyleType: "none",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
  padding: 0,
  margin: "0 0 100px 0",
  color: textInvertColor,
};

const FeatureStyle = (mobile) => ({
  margin: 10,
  flexBasis: mobile ? "100%" : "33%",
});

const FeatureTitle = {
  fontWeight: "bold",
  fontSize: "1.3rem",
  color: highlight,
};

const IconStyle = {
  width: "33%",
};

const Body = () => {
  const mobile = isMobile();
  const features = CONFIG.MAIN_PAGE.FEATURES;
  return (
    <div style={BodyStyle}>
      <h1>{CONFIG.MAIN_PAGE.FEATURES_HEADER}</h1>
      <ul style={FeatureListStyle}>
        {features.map(({ title, line, icon, extra }) => (
          <li key={title} style={FeatureStyle(mobile)}>
            <img style={IconStyle} src={icon} alt={`${title} icon`} />
            <br />
            <span style={FeatureTitle}>{title}</span>
            <br />
            {line}
            <br />
            {extra && extra}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Body;
