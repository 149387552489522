import React, { Fragment, useEffect, useRef, useState } from "react";
import Menu from "../Menu";
import { colors, isMobile } from "../../styles";
import { matchPath, withRouter } from "react-router-dom";
import CONFIG from "../../CONFIG";

const { accent, primary, textInvertColor } = colors;

const HeaderStyle = {
  backgroundImage: `linear-gradient(to right, ${primary} , ${accent})`,
  display: "flex",
  fontSize: "calc(10px + 2vmin)",
  color: textInvertColor,
  justifyContent: "space-between",
  alignItems: "center",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
};

const TOP_MARGIN_ROUTES = [
  "/about/location/",
  "/location/troubleshooting/",
  "/about/",
  "/privacy/",
  "/terms/",
];

const Header = ({ location, share }) => {
  const SHOW_MENU = matchPath(location.pathname, { path: "/", exact: true }); // Only show link creation on main page
  const ADD_TOP_MARGIN = TOP_MARGIN_ROUTES.includes(
    location.pathname.endsWith("/")
      ? location.pathname
      : location.pathname + "/"
  );

  // Used to determine top margin for page
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  useEffect(() => setHeight(ref.current.clientHeight + 20), []);
  const TOP_MARGIN_STYLE = { marginTop: `${height}px` };

  return (
    <Fragment>
      {ADD_TOP_MARGIN && <div style={TOP_MARGIN_STYLE} />}
      <header style={HeaderStyle} ref={ref}>
        {CONFIG.GLOBAL.LOGO()}
        {SHOW_MENU && !isMobile() && <Menu share={share} />}
      </header>
    </Fragment>
  );
};

export default withRouter(Header);
