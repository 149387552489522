import React from "react";
import { default as LoaderComponent } from "react-loader-spinner";

import { colors } from "../../styles";

const { highlight } = colors;

const Loader = ({ type = "TailSpin", ...rest }) => {
  return (
    <LoaderComponent
      type={type}
      color={highlight}
      height={200}
      width={200}
      {...rest}
    />
  );
};

export default Loader;
