import React from "react";
import { colors, isMobile } from "../../styles";
import { Link } from "react-router-dom";
import CONFIG from "../../CONFIG";

const { textInvertColor, primary, accent } = colors;
const FooterStyle = {
  padding: 10,
  backgroundImage: `linear-gradient(to right, ${primary} , ${accent})`,
  color: textInvertColor,
  display: "flex",
  justifyContent: isMobile() ? "center" : "space-around",
  alignItems: "flex-start",
  flexWrap: "wrap",
  flexGrow: 1,
};

const LinksStyle = {
  display: "flex",
  margin: 10,
};

const LinkStyle = {
  margin: 10,
  textDecoration: "none",
};

const Footer = ({ style }) => {
  const Links = CONFIG.FOOTER.LINKS;
  return (
    <footer style={{ ...FooterStyle, ...style }}>
      <div style={LinksStyle}>
        {Links.map(({ title, route }) => (
          <Link key={title} style={LinkStyle} to={route}>
            {title}
          </Link>
        ))}
      </div>
      {CONFIG.FOOTER.MADE_BY()}
    </footer>
  );
};

export default Footer;
