import React from "react";
import Loader from "../Loader";
import { colors } from "../../styles";

const { primary, accent } = colors;

const LoaderPageStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "100%",
  backgroundImage: `linear-gradient(to right, ${primary} , ${accent})`,
};

const LoaderPage = (props) => (
  <div style={LoaderPageStyle}>
    <Loader />
  </div>
);

export default LoaderPage;
