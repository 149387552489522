import React, { useEffect, Fragment, useState } from "react";
import axios from "axios";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "file-saver";
import { getFiles, postMetadata, deleteFiles, userIsOnIOS } from "../../utils";
import { colors } from "../../styles";
import Loader from "../Loader";
import Button from "../Button";

import CONFIG from "../../CONFIG";

const {
  ONETIME,
  BUTTON_TEXT,
  ONE_TIME_USE_TEXT,
  DEFAULT,
  DOWNLOADING,
  DELETING,
  SUCCESS,
  ERROR,
} = CONFIG.RETRIEVER_PAGE;

const { primary, accent, textInvertColor } = colors;

const RetrieverStyle = {
  backgroundImage: `linear-gradient(to right, ${primary} , ${accent})`,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  color: textInvertColor,
  fontSize: "1.3rem",
};

const FileRetriever = ({ id, onetime, password, geolocation, zip }) => {
  const [message, setMessage] = useState(DEFAULT);
  const [loading, setLoading] = useState(false);
  const [fileIsDownloaded, setDownloaded] = useState(false);

  const downloadFiles = async () => {
    try {
      setLoading(true);
      setMessage(DOWNLOADING);
      const params = { id };
      if (password) {
        params.password = password;
      }
      if (geolocation) {
        params.geolocation = geolocation;
      }
      // eslint-disable-next-line no-unused-vars
      const [_, { data }] = await Promise.all([
        postMetadata({
          msg: `Retrieving file ${id} - password ${password} / geolocation - ${JSON.stringify(
            geolocation
          )} / onetime - ${onetime}`,
        }),
        getFiles(params),
      ]);
      if (zip) {
        const zip = new JSZip();
        await Promise.all(
          data.map(async (url) => {
            const res = await JSZipUtils.getBinaryContent(url);
            // Get filename from url
            const split = url.split("/");
            const finalSplit = split[split.length - 1];
            const filename = finalSplit.substring(0, finalSplit.indexOf("?X"));
            // Write file name and prepend index in case of duplicates
            zip.file(`${filename}`, res, { binary: true });
          })
        );
        const blob = await zip.generateAsync({ type: "blob" });
        saveAs(blob, id + ".zip");
      } else {
        data.map(async (url) => {
          const { data } = await axios.get(url);
          // Get filename from url
          if (data) {
            const split = url.split("/");
            const finalSplit = split[split.length - 1];
            const filename = finalSplit.substring(0, finalSplit.indexOf("?X"));
            //   // Write file name and prepend index in case of duplicates
            const blob = new Blob([data]);
            saveAs(blob, decodeURI(filename));
          }
        });
      }

      if (onetime) {
        setMessage(DELETING);
        await Promise.all([
          postMetadata({ msg: `Retrieved ${id}` }),
          postMetadata({ msg: `Deleting file ${id}` }),
          deleteFiles(id),
        ]);
      } else {
        await postMetadata({ msg: `Retrieved ${id}` });
      }
      await postMetadata({ msg: `File ${id} retrieval complete` });
      setMessage(SUCCESS);
    } catch (err) {
      await postMetadata({ msg: `Error retrieving file ${id} - ${err}` });
      setMessage(ERROR);
    } finally {
      setLoading(false);
      setDownloaded(true);
    }
  };

  useEffect(() => {
    // This we must have the user download a file via interaction on iOS - https://www.npmjs.com/package/file-saver
    if (!userIsOnIOS) downloadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const Body = !userIsOnIOS ? (
    <h4>{message}</h4>
  ) : !loading && !fileIsDownloaded ? ( // if on IOS we need to have the user click a button to download
    <Fragment>
      <h4>{DEFAULT}</h4>
      <Button onClick={downloadFiles}>{BUTTON_TEXT}</Button>
    </Fragment>
  ) : (
    !loading && fileIsDownloaded && <h4>{SUCCESS}</h4>
  );
  return (
    <div style={RetrieverStyle}>
      {onetime && (
        <Fragment>
          <h3>{ONE_TIME_USE_TEXT}</h3>
          <div>{ONETIME}</div>
        </Fragment>
      )}
      {Body}
      {loading && <Loader />}
    </div>
  );
};

export default FileRetriever;
