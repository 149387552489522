import React, { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
// import { record } from "rrweb";
import { postMetadata } from "../../utils";

// HOC to post metadata on route change
const Instrumented = ({ children, location }) => {
  // const [userId, setUserId] = useState("");
  const pageName = location.pathname;

  useEffect(() => {
    const initialize = async () =>
      await postMetadata({ msg: `${pageName} route visited` });
    initialize();
  }, [pageName]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     function receiveMessage(event) {
  //       if (event.origin !== "http://localhost:3000") return;
  //       setUserId(event.data);
  //     }
  //     const iframe = document.getElementsByTagName("iframe")[0].contentWindow;
  //     window.addEventListener("message", receiveMessage, false);
  //     iframe.postMessage(
  //       { action: "SET_URL", payload: window.location.toString() },
  //       "http://localhost:3000"
  //     );
  //     iframe.postMessage({ action: "FETCH_USER_ID" }, "http://localhost:3000");
  //   }, 1000);
  // }, []);

  // useEffect(() => {
  //   const iframe = document.getElementsByTagName("iframe")[0].contentWindow;
  //   record({
  //     emit(event) {
  //       iframe.postMessage(
  //         { action: "SEND_EVENT", payload: event },
  //         "http://localhost:3000"
  //       );
  //     },
  //   });
  // }, []);

  // console.log(userId);

  return <Fragment>{children}</Fragment>;
};

export default withRouter(Instrumented);
