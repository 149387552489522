import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// Components
import Button from "./components/Button";
import CountdownTimer from "./components/CountdownTimer";
// Images
import fast from "./assets/fast.png";
import secure from "./assets/secure.png";
import free from "./assets/free.png";
import simple from "./assets/simple.png";
import timer from "./assets/timer.png";
import password from "./assets/password.png";
import check from "./assets/check.png";
import uncheck from "./assets/uncheck.png";
import bolt from "./assets/bolt.png";
// Shared CSS
import { LinkStyle, colors, isMobile } from "./styles";

// Styles
const { accent, highlight, textInvertColor } = colors;

const TeamAcknowledgeStyle = { margin: 20 };
const HighlightStyle = { color: highlight };
const AccentStyle = { color: accent };
const RightAlignText = {
  textAlign: "right",
  display: "flex",
  flexDirection: "column",
};
const CenterAlignedText = {
  textAlign: "center",
};

const LogoStyle = {
  fontSize: isMobile() ? "1.5rem" : "2rem",
  margin: isMobile() ? 8 : "10px 20px",
  textDecoration: "none",
  color: textInvertColor,
};

// Constants
const SITE_NAME = "The Quick File";
const SITE_URL = "thequickfile.com";
const SITE_CREATION_YEAR = "2016";
const SITE_LOGO = () => (
  <Fragment>
    <img width={30} alt="logo" src={bolt} />
    The Quick File
  </Fragment>
);

const CONFIG = {
  NOT_FOUND: {
    HEADER: "File Not Found",
    SUB: "This file ID was not found, please check the URL and try again",
    BUTTON: () => (
      <Link style={LinkStyle} to="/">
        <Button>Go Home</Button>
      </Link>
    ),
  },
  LINK_EXPIRED: {
    HEADER: "This Link Has Expired",
    SUB:
      "Please reach out to the link creator and have them recreate the link if you still need to access this file.",
    BUTTON: () => (
      <Link style={LinkStyle} to="/">
        <Button>Go Home</Button>
      </Link>
    ),
  },
  GEOPROTECT: {
    WHY: () => (
      <Link style={LinkStyle} to="/about/location">
        Why do we request this info?
      </Link>
    ),
    TROUBLE_SHOOTING: () => (
      <Link style={LinkStyle} to="/location/troubleshooting">
        Having issues?
      </Link>
    ),
    DEFAULT_MESSAGE:
      "To ensure the fastest download, we use your location to determine the best server for you to download your file from. This location is not stored in any database.",
    USER_DENIED:
      "Device location must be enabled - enable, refresh and try again",
    UNAVAILABLE:
      "Location unavailable, ensure device location is enabled, refresh and try again",
    TIMEOUT:
      "Location timed out, ensure device location is enabled and try again",
    UNSUPPORTED:
      "Location is not supported in this browser, please try a different browser or device and try again",
    DENIED:
      "Location Denied: The creator of this link has restricted access from this location.",
  },
  PASSWORD_PROTECT: {
    HEADER: "This file has been password protected",
    ERROR: "Incorrect password, please try again",
  },
  GEOPROTECT_TROUBLESHOOTING: {
    HEADER: "Download Troubleshooting",
  },
  GEOPROTECT_ABOUT: {
    HEADER: "Why do we ask for your location?",
    SUBHEADER: () => (
      <Fragment>
        <span>
          <b>Author:</b> Erik Andersson
        </span>
        <br />
        <span>
          <b>Date:</b> 20 Feb, 2020
        </span>
      </Fragment>
    ),
    CONTENT: () => (
      <Fragment>
        We have been working hard to create the most secure file sharing
        solution on the planet. {SITE_NAME} is dedicated to ensuring that all
        our customers - no matter they are located - have the best file sharing
        experience possible. To ensure that we are able to provide a
        high-quality service for <b>free</b>, we have started to request
        location access in order to determine the best server for you to
        download your files. This allows us to save money on data transport
        costs by not sending your file from a data center on the opposite side
        of the world and also allows for you to get access to your files as fast
        as possible. <br />
        <br />
        <b>
          This information is not stored in a database or sold to third-parties
          per our{" "}
          <Link style={HighlightStyle} to="/terms">
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link style={HighlightStyle} to="/privacy">
            Privacy Policy
          </Link>
          . All information is encrypted to our backend systems to ensure that
          no one can intercept your data.
        </b>
        <br />
        <br />
        With data privacy being more important than ever, we understand that
        this information must be protected which is why we have strenuous
        requirements on our encryption and transport protocols. You can view our
        security certification in real time{" "}
        <a
          style={HighlightStyle}
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.ssllabs.com/ssltest/analyze.html?d=${SITE_URL}`}
        >
          here
        </a>
        .
        <br />
        <br />
        We hope that you have a wonderful experience using {SITE_NAME}.
        <br />
        <br />
        <div style={RightAlignText}>
          <b>- Erik</b>
          <span style={AccentStyle}>Head of Engineering,</span>
          <span style={AccentStyle}> {SITE_NAME}</span>
        </div>
      </Fragment>
    ),
  },
  GLOBAL: {
    REQUIRED_ACCURACY: 200,
    SITE_NAME,
    PRIMARY_BUTTON_TEXT: "Get Started",
    LOGO: () => (
      <Link to="/" style={LogoStyle}>
        {SITE_LOGO()}
      </Link>
    ),
  },
  RETRIEVER_PAGE: {
    ONETIME:
      "This file will be deleted after download and you will be unable to access it again.",
    DEFAULT: "Your file is available for download",
    DOWNLOADING: "Downloading your file now...",
    DELETING: "Deleting file...",
    SUCCESS: "Successfully downloaded file. You can now close this window.",
    ERROR:
      "There was an issue fetching your file. Please refresh and try again.",
    ONE_TIME_USE_TEXT: "One-time Use",
    BUTTON_TEXT: "Download",
  },
  MAIN_PAGE: {
    HERO: {
      TAG_LINE: "Say Goodbye to Insecure File Sharing",
      SUB_TAG: `Tired of ads and being tracked? We are too - ${SITE_NAME} is here to change things. We are working to create secure, free, and anonymous filesharing for everyone.`,
    },
    FEATURES_HEADER: "Why use The Quick File?",
    FEATURES: [
      {
        title: "Password Protect",
        line: "Add a password to your file to keep it safe",
        icon: password,
      },
      {
        title: "Secure",
        line:
          "All pages and file transfers are encrypted - we use TLS1.2+, SHA256 and RSA-4096 for encryption",
        icon: secure,
        extra: (
          <a
            style={HighlightStyle}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.ssllabs.com/ssltest/analyze.html?d=${SITE_URL}`}
          >
            View SSL results
          </a>
        ),
      },
      {
        title: "Fast",
        line:
          "No download / upload limits or caps on your download / upload speeds",
        icon: fast,
      },
      {
        title: "Free",
        line: "No payment method required",
        icon: free,
      },
      {
        title: "Simple",
        line: "No BS - just easy, simple file sharing",
        icon: simple,
      },
    ],
  },
  ABOUT_PAGE: {
    HEADER: "Safe, Secure File Sharing for everyone",
    CONTENT: () => (
      <Fragment>
        {SITE_NAME} started as a part of gofile.io in {SITE_CREATION_YEAR} to
        create a better way to share files. The big name file sharing providers
        (Dropbox, Google Drive, etc.) mine your data, files and sell YOU as the
        product. They put arbitrary upload and download caps, file storage
        limits and charge you for minimal features. They have a limited suite of
        options to help you protect your data and securely share files.
        <h3 style={{ ...AccentStyle, ...CenterAlignedText }}>
          There has to be a better way...
        </h3>
        <b>Now there is.</b> We understand you just want to share files without
        giving up privacy or paying high prices. {SITE_NAME} removes these
        limitations and democratizes file sharing - any one can use our services
        as long as they abide by our <Link to="/terms">Terms of Service</Link>{" "}
        and understand our <Link to="/privacy">Privacy Policy</Link>.<br />
        <br />
        We hope you enjoy our services and hope we can help you better connect
        with individuals in the online community.
      </Fragment>
    ),
  },
  PRIVACY_PAGE: {
    HEADER: "Privacy",
    UPDATED_TIME: "June 22, 2019",
    PRIVACY_ITEMS: [
      {
        text: `${SITE_NAME} does not and will not sell or license any of the data submitted by our users.`,
      },
      {
        text: `${SITE_NAME} does not use any analytics service (Google Analytics, Facebook tracking, etc). Our business survives off of donations from users like you.`,
      },
      { text: "All information is encrypted in transit to and from our site." },
      {
        text: `${SITE_NAME} does not claim ownership over files submitted to our site - you maintain the ownership of the files, we just help you transfer them.`,
      },
    ],
  },
  TERMS_PAGE: {
    HEADER: "Terms Of Service",
    UPDATED_TIME: "June 18, 2019",
    TERMS: [
      "These terms are binding and apply to any use of the services by you and anyone who you allow to access your data or our services. By using our services you and they irrevocably agree to these terms. If you do not like these terms or don't want to be bound, you can't use our services. If you do not comply with these terms, we reserve the right to terminate your service and therefore delete your data.",
      "We can change these terms at any time via our website. Your continued use means that you agree to the changed terms.",
      "You must maintain copies of all data stored by you on our services. We do not make any guarantees that there will be no loss of data or the services will be bug free. You should download all data prior to termination of services.",
      "You can't do anything that would damage, disrupt or place an unreasonable burden on our website or service or anyone else's use of our website, or a service including but not limited to denial of service attacks or similar.",
      "You can't infringe anyone else's intellectual property (including but not limited to copyright) or other rights in any data.",
      "You can't resell or otherwise supply our services to anyone else without our prior written consent.",
      "You can't use our services: to store, use, download, upload, share, access, transmit, or otherwise make available, data in violation of any law in any country (including to breach copyright or other intellectual property rights held by us or anyone else) OR to send unwelcome communications of any kind (including but not limited to unlawful unsolicited commercial communications) to anyone OR to abuse, defame, threaten, stalk or harass anyone, or to harm them as defined by any law in any jurisdiction OR to store, use, download, upload, share, access, transmit, or otherwise make available, unsuitable, offensive, obscene or discriminatory information of any kind OR to upload anything or otherwise introduce any spyware, viruses, worms, trojan horses, time bombs or bots or any other damaging items which could interfere with our, or anyone else's, network, device or computer system OR to attempt to gain unauthorised access to any services other than those to which you have been given express permission to access.",
      "You are not allowed to, and you can't let anyone else use, copy, alter, distribute, display, licence, modify or reproduce, reverse assemble, reverse compile, communicate, share, transmit or otherwise make available, any of our code, content, copyright materials, intellectual property or other rights without getting our permission, other than in order to use our services as intended or as allowed under any open source licences under which we use intellectual property provided by others.",
      "We respect the copyright of others and require that users of our services comply with copyright laws. You are strictly prohibited from using our services to infringe copyright. You may not upload, download, store, share, access, display, stream, distribute, e-mail, link to, communicate, transmit, or otherwise make available any files, data, or content that infringes any copyright or other proprietary rights of any person or entity. See Copyright.",
      "We reserve the right to remove data alleged to be infringing without prior notice, at our sole discretion, and without liability to you. In appropriate circumstances, we will also terminate your account if we consider you to be a repeat infringer.",
      "We will try to give you access to our services all the time, but we do not make any promises or provide you with a warranty that our services will be without any faults, bugs or interruptions.",
      "Whilst we intend that the services should be available 24 hours a day, seven days a week, it is possible that on occasions the website or services may be unavailable to permit maintenance or other development activity to take place or be periodically interrupted for reasons outside our control.",
      "Information on our website will change regularly. We will try to keep our website up to date and correct, but again, we do not make any promises or guarantees about the accuracy of the information on our website.",
      "We do not warrant that the services will meet your requirements or that they will be suitable for any particular purpose.",
      "We also aren't legally responsible for: any corruption or loss of data or other content which you or anyone else may experience after using services, or any problems you may have when you view or navigate our website. any loss or damage if you do not follow these terms. any actions or non-actions of other people which disrupt access to our services. Including the content and nature of any data that you upload, access or share. The content of ads appearing on our website (including links to advertisers' own websites) as the advertisers are responsible for the ads and we don't endorse the advertisers' products. The content of other people's websites even if a link to their website is included on our website.",
    ],
  },
  ENABLED_PROTECTIONS: {
    HEADER: "File Protection",
    PROTECTIONS: (meta) =>
      [
        {
          active: true,
          icon: meta.password ? check : uncheck,
          title: "Password",
        },
        {
          active: true,
          icon: meta.onetime ? check : uncheck,
          title: "One-time Use",
        },
        {
          active: true,
          icon: meta.expires ? check : uncheck,
          title: `Link expiration`,
        },
        {
          active: !!meta.expires,
          icon: timer,
          title: (
            <CountdownTimer prefix={"Expires in "} target={meta.expires} />
          ),
        },
      ].filter((i) => i.active),
    BUTTON: "Retrieve",
  },
  FOOTER: {
    LINKS: [
      {
        title: "About",
        route: "/about",
      },
      {
        title: "Terms",
        route: "/terms",
      },
      {
        title: "Privacy",
        route: "/privacy",
      },
    ],
    MADE_BY: () => (
      <span style={TeamAcknowledgeStyle}>
        {" "}
        © {SITE_CREATION_YEAR}, made with{" "}
        <span style={AccentStyle}>{`<3`}</span> by the{" "}
        <span style={HighlightStyle}>{SITE_NAME} Team</span>{" "}
      </span>
    ),
  },
};

export default CONFIG;
