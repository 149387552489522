import React from "react";
import Footer from "../components/Footer";
import { AboutPageStyle, ContentStyle } from "../styles";
import CONFIG from "../CONFIG";
import { colors } from "../styles";

const { accent } = colors;
const {
  USER_DENIED,
  UNAVAILABLE,
  TIMEOUT,
  UNSUPPORTED,
  DENIED,
} = CONFIG.GEOPROTECT;

const HeaderStyle = {
  color: accent,
};

const GeoProtectTroubleshooting = () => (
  <div style={AboutPageStyle}>
    <h1 style={{ textAlign: "left" }}>
      {CONFIG.GEOPROTECT_TROUBLESHOOTING.HEADER}
    </h1>
    <div style={ContentStyle}>
      Having issues accessing a file due to location issues? Read the following
      scenarios to figure out how to best solve your issue and access your
      files.
      <h3 style={HeaderStyle}>1. {USER_DENIED}</h3>
      This issue occurs when you or your browser do not allow access to your
      location when prompted. Without approving the location request prompt, we
      are unable to forward you to your download link. Please enable location on
      your device and try again. If this does not resolve we recommend trying
      another device or browser. <br />
      <br />
      <b>NOTE:</b> We do not store your location data - it is only used to
      ensure that you are allowed to access the file.
      <h3 style={HeaderStyle}>2. {UNAVAILABLE}</h3>
      This can occur when your device location is turned off OR if you do not
      have a strong enough signal. Try toggling your location on and off on your
      device and trying again. If that does not resolve, attempt to access on a
      different device.
      <h3 style={HeaderStyle}>3. {TIMEOUT}</h3>
      This means that your signal is not strong enough or that your device
      location is not accurate enough. We recommend restarting your device,
      toggling your location or trying a different device if this continues to
      occur.
      <h3 style={HeaderStyle}>4. {UNSUPPORTED}</h3>
      Some browsers and devices (especially older ones) do not have support for
      location access. Please update your browser, try a different browser or a
      different device.
      <h3 style={HeaderStyle}>5. {DENIED}</h3>
      You are in an area that has been restricted (North Korea) - you will be
      unable to access the link while in that geographic region.
      <br />
      <br />
      <h3 style={HeaderStyle}>Still having issues?</h3>
      Note that VPN's can also cause issues with location access - if you are
      attempting to connect via a VPN, disconnect and try again.
      <br />
      <br />
    </div>
    <Footer style={{ alignSelf: "stretch" }} />
  </div>
);

export default GeoProtectTroubleshooting;
